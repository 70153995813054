<template>
  <div>
    <vs-table multiple v-model="selected" max-items="50" :data="$store.state.MwbLeads">
      <template slot="thead">
        <vs-th> Can-ID</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>Company</vs-th>
        <vs-th>Course</vs-th>
        <vs-th>Level</vs-th>
        <vs-th>SPOC</vs-th>
        <!-- <vs-th>ELIGIBILITY CODE</vs-th> -->
        <vs-th>Next Contact</vs-th>
        <vs-th>MHP</vs-th>
        <vs-th>View Info</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.identity }}</div>
            </vs-td>
            <vs-td :data="tr.email">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                  <vx-tooltip
                    :title="tr.spoc_name + ' (' + tr.engagement_added_on + ')'"
                    :text="tr.engagement_details"
                  >{{ tr.person_name }}</vx-tooltip>
                </vs-col>
              </vs-row>
            </vs-td>
            <vs-td>
              <div>{{ tr.company }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.courses }}</div>
            </vs-td>

            <vs-td>
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.level }}</b>
              </vs-chip>
            </vs-td>

            <vs-td>
              <div>{{ tr.spoc_name }}</div>
            </vs-td>
            <!-- <vs-td>
              <div>{{ tr.eligibility_data }}</div>
            </vs-td>-->
            <vs-td>
              <div>{{ unixTimestampConvert(tr.next_call) }}</div>
            </vs-td>

            <vs-td>
              <div v-if="getMHPIndication(tr.next_call)">
                <vs-icon icon="warning" size="small" color="warning"></vs-icon>
              </div>
              <!-- <div>
                <img :src="getMHPIndication(tr.next_call)" />
              </div>-->
            </vs-td>

            <vs-td>
              <vs-row>
                <!-- <img
                  @click="openPopupEmit(tr)"
                  src="https://img.icons8.com/material-outlined/24/000000/info.png"
                />
                <img
                  @click="additional('open-student-details-popup-call', tr)"
                  src="https://img.icons8.com/ios-glyphs/24/000000/phone.png"
                />
                <img
                  @click="EmitEvent('open-email-engagement', tr)"
                  src="https://img.icons8.com/windows/24/000000/gmail.png"
                />
                <img
                  @click="additional('open-student-details-popup-whatsapp', tr)"
                  src="https://img.icons8.com/material-rounded/24/000000/chat.png"
                />-->
                <div @click="openPopupEmit(tr)">
                  <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                </div>
                <!-- <div @click="additional('open-student-details-popup-call', tr)">
                  <vs-icon icon="phone" size="small" color="dark"></vs-icon>
                </div>
                <div @click="EmitEvent('open-email-engagement', tr)">
                  <vs-icon icon="email" size="small" color="dark"></vs-icon>
                </div>
                <div @click="additional('open-student-details-popup-whatsapp', tr)">
                  <vs-icon icon="chat" size="small" color="dark"></vs-icon>
                </div>-->
                <enrollentbutton :lead="tr" v-if="tr.enrollment_added === 0" />
                <!-- <img
                  v-if="tr.enrollment_added === 1"
                  src="https://img.icons8.com/color/24/000000/checked--v2.png"
                />-->
                <div v-if="tr.enrollment_added === 1">
                  <vs-icon icon="check_circle_outline" size="small" color="dark"></vs-icon>
                </div>
                <!-- <enrollentbutton :lead="tr" /> -->
                <!-- <img
                  src="https://img.icons8.com/windows/24/000000/plus.png"
                  @click="addengagement(tr)"
                />-->
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "../eventbus.js";
// import axios from "axios";
import moment from "moment";
import enrollentbutton from "./buttons/AddEnrollmentButton.vue";
// import constants from "../../../constants.json";

Vue.config.productionTip = false;
export default {
  components: {
    enrollentbutton
  },
  props: ["totalpagination"],
  data() {
    return {
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
      tablelinks: 0,
      currentTablePage: 1,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  mounted() {
    document
      .getElementsByClassName("vs-table--thead")[0]
      .addEventListener("click", this.selectallFunc, false);
    EventBus.$on("open-email-bdeleads", () => {
      if (this.selectall === false) {
        EventBus.$emit("open-email-engagement", this.selected);
      } else if (this.selectall == true) {
        EventBus.$emit("open-email-leadsdatatable", this.notselectedleads);
      }
    });
  },
  watch: {
    selected: function(value) {
      this.notselectedleads = [];
      this.$store.state.MwbLeads.forEach(lead => {
        if (value.indexOf(lead) === -1) {
          this.notselectedleads.push(lead);
        }
      });
      // console.log(value)
    },
    currentTablePage: function(val) {
      this.getMwbLeads(val);
    }
  },
  methods: {
    selectallFunc() {
      this.selectall = !this.selectall;
    },
    getMHPIndication(next_call) {
      if (next_call === null) {
        return "";
      }
      let today = new Date().setHours(0, 0, 0, 0);
      today = today.valueOf();
      let next_call_date = moment.unix(next_call).format("YYYY-MM-DD");
      today = moment.unix(today / 1000).format("YYYY-MM-DD");
      let comaprision = moment(next_call_date).isBefore(today);
      let same = moment(next_call_date).isSame(today);
      // console.log(next_call_date, today);
      // console.log(comaprision);
      if (comaprision || same) {
        // let string ="https://img.icons8.com/color/24/000000/warning-shield.png";
        return true;
      } else {
        return false;
      }
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    openPopupEmit(payload) {
      this.openBigPopup(payload.id);
      /* let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${payload.can_id}&person_id=${payload.person_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.$store.commit("UPDATE_NEW_DATA", [
            payload,
            response.data.engagements,
            response.data.mobile,
            response.data.emails
          ]);
          EventBus.$emit("open-student-details-popup", payload);
          if (emit !== undefined) {
            EventBus.$emit(emit);
          }
          this.$vs.loading.close();
        })
        .catch(error => {
          this.handleError(error);
        }); */
    },
    addengagement(payload) {
      console.log("emitting");
      EventBus.$emit("open-enrollment-popup", payload);
    },
    fetchbdeName(bdeid) {
      let name = "";
      this.$store.state.bdes.forEach(bde => {
        if (bde.id === bdeid) {
          name = bde.bdename;
        }
      });
      return name;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    fetchEngagementDate(latest_engagement) {
      let date = "";
      this.$store.state.engagements.forEach(engagement => {
        if (engagement.engagement === latest_engagement) {
          date = engagement.engagement_date;
        }
      });
      return date;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    engagementlast(engagements) {
      return engagements.slice(-1)[0];
    }
  }
};
</script>

<style></style>
